import React, { Component } from 'react';
import { Link, BrowserRouter } from 'react-router-dom'
import TransactionForm from './TransactionForm'
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import axios from 'axios'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment';
import RetailerForm from './RetailerForm'
import StakeTicketDetails from '../gamedraw/StakeTicketDetails';

const local = { "format": "DD-MM-YYYY", "sundayFirst": false }
var amountformat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
const desc = {
    '0':'Reconciliation by Alpha',
    '1':'Purchase of credit from Alpha',
    '-1':'Reversal of credit by Alpha',
    '2':'Purchase of credit from Agent',
    '-2':'Reversal of credit by Agent',
    '3':'Purchase of credit from Wallet',
    '4':'Ticket sales'
}
const momo = {
    '1':'Loading of wallet by Momo',
    '2':'Using of wallet to purchase credit'
}

const mobile = {
    '0':'Wallet initialization',
    '1':'Loading of wallet by Momo',
    '2':'Using of wallet to stake',
    '3':'Cashout to Momo'
}

export default class RetailerTransList extends Component {
    constructor() {
        super()
        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()
        this.state = {
            formItems: { operator: '', agent: '', username: '' ,startDate: moment().subtract(29, 'days'),endDate: moment(),
            curuser: this.auth.getUser(), token: this.auth.getToken()},
            batchData: [], operators: [], agent: [], goperator: '', retailer: [],batch: 1,userTill: [], client: [],

            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            visible: false,
            ticketSerial:'',
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            loader: false
        }
    }

    componentDidMount() {
        this.setState({ loader: true })
        this.getUserType()
        
        this.getAgent(this.auth.getUser())
    }

    getJournaldata = () => {
        this.setState({ loader: true })
        let formItems = this.state.formItems

        //alert(JSON.stringify(formItems))
        let formData = this.auth.encryptData(JSON.stringify(formItems))
        axios(this.config.PostOptions(formData, 'transaction/wapp/journal/retailer'))
        .then((res) => {
            if(isNaN(res.data)){
                let journaldata = this.auth.decryptData(res.data);

                //alert(JSON.stringify(journaldata));
                this.setState({ journaldata, loader: false });
            }else{
                this.auth.doAlert(parseInt(res.data));
            }   
        });
    }
   
    getAgent = (operator) => {
        if (operator != '') {
        if(isNaN(operator)){operator = 'admin'}
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() 
            + '/agentdetails_rep/' + operator)
                .then(res => {
                    if(isNaN(res.data)){
                    let agent = this.auth.decryptData(res.data)
                    let formItems = this.state.formItems
                    formItems['agent'] = (agent[0] != undefined) ? agent[0].username : agent.username
                    
                    this.getRetailer(formItems.agent)
                    this.setState({formItems, agent })
                }else{
                    this.auth.doAlert(parseInt(res.data))
                }
            })
        }
    }
    
    getRetailer = (agent) => {
        this.setState({ loader: true })
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/' + agent + '/3')
        .then(res => {
                if(isNaN(res.data)){
                let retailer = this.auth.decryptData(res.data)
                let formItems = this.state.formItems
                formItems['username'] = (retailer[0] != undefined) ? retailer[0].username : formItems.username
               
                this.setState({ retailer, visible:true, loader: false })
            }else{
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    getUserType = () => {
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/3')
        .then(res => {
            if (isNaN(res.data)) {
                let userType = this.auth.decryptData(res.data)

                this.setState({ userType })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
        this.$http.get('transaction/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertill')
        .then(res => {
            if (isNaN(res.data)) {
                let usertill = this.auth.decryptData(res.data)
                this.setState({ usertill })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + '/usertypes/' + this.state.formItems.retailer + '/4')
        .then(res => {
            if (isNaN(res.data)) {
                let client = this.auth.decryptData(res.data)
                this.setState({ client })
            } else {
                this.auth.doAlert(parseInt(res.data))
            }
        })
    }

    handleNewBatch = () => {
        ReactDOM.render(
            <TransactionForm batch={this.state.batch} />,
            document.getElementById('agent_inner')
        )
    }

    handleChange = (e) => {
        let ptype = e.target.value
        let formItems = this.state.formItems
        let visible = this.state.visible
        formItems[e.target.name] = ptype
       
        if(e.target.name=="agent"){
            visible = false
            this.getRetailer(e.target.value)
        }
        this.setState({ formItems, visible })
    }

    handleEvent = (event, picker) => {
        let formItems = this.state.formItems
        formItems['startDate'] = picker.startDate
        formItems['endDate'] = picker.endDate

        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate,
            formItems
        });
    }

    showTicketDetails = (ticketSerial) => {
        this.setState({ ticketSerial });
    }

    dateFormat = (cell, row) => moment(cell).format("DD MMM, YYYY @ h:mm a").toLocaleString();
    amountFormat = (cell, row) => {
        return cell<0 ? `(${amountformat.format(Math.abs(cell))})` : `${amountformat.format(cell)}`;
    }
    desFormat = (cell, row) => {
        let notes = (row.tillType == 2) ? momo[cell] : desc[cell];
        if(this.state.formItems.username == "mobileapp" && row.tillType == 2){
            notes = mobile[cell];
        }
        return notes;
    }
    drcrFormat = (cell, row) => {
        let amount =  0 ;
        if(row.tillType == 2){
            if(cell == 0){
                amount = (row.credits > 0) ? `${amountformat.format(row.credits)}` : `(${amountformat.format(row.debits)})` ;
            }else{
                amount = (cell == 2) ? `(${amountformat.format(row.credits)})` : `${amountformat.format(row.debits)}`;
            }
        }else{
            if(cell == 0){
                amount = (row.credits > 0) ? `${amountformat.format(row.credits)}` : `(${amountformat.format(row.debits)})` ;
            }else{
                amount = ([-1,-2,4].includes(cell)) ? `(${amountformat.format(row.credits)})` : `${amountformat.format(row.debits)}`;
            }
        }

        return amount;
    }

    ticketFormat = (cell, row) => {
        let id = (cell == '') ? row.id: cell;
        return(<BrowserRouter>
            <Link to='#' onClick={() => this.showTicketDetails(id)}>
                <span className="text-primary" data-toggle="modal" data-target="#winTicketDetails" >{id}</span>
            </Link>
        </BrowserRouter>)
    }

    loading = () => <div id="loader"></div>
    
    render() {
        if(this.state.loader){
            return this.loading()
        }else{
            let start = this.state.startDate.format('MMMM D, YYYY');
            let end = this.state.endDate.format('MMMM D, YYYY');
            let label = start + ' - ' + end;

            let {startDate, endDate, ranges, agent, retailer, visible, journaldata, ticketSerial, formItems} = this.state;

            if (start === end) {
                label = start
            }

            return (
                <div className="row">
                    <div className="col-xs-12 clearfix">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">DateRange: </button>

                            <DatetimeRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                ranges={ranges}
                                onEvent={this.handleEvent}
                                className="selected-date-range-btn btn btn-default">
                                    <div className="pull-left">
                                        <i className="fa fa-calendar" />&nbsp; &nbsp;<span>{label}</span>
                                    </div>
                                    <div className="pull-right">&nbsp; &nbsp;<i className="fa fa-angle-down" /></div>
                            </DatetimeRangePicker>
                            <button className="btn btn-default">Agent: </button>
                            <select id="agent" name="agent" onChange={this.handleChange} value={formItems.agent} className="btn btn-default" style={{ width: '200px', height: '30px' }}>
                                {agent.map(opt =>
                                    <option value={opt.username}>{opt.fullname}</option>
                                )}
                            </select>
                        
                        </div>
                        <div className="btn-group btn-group-sm pull-right" >
                            <button className="btn btn-success" data-toggle="modal" data-target="#retailerModal">Retailer Transfer</button>
                        </div>
                    </div>
                    <div className="col-xs-12">
                        {visible && 
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Retailer: </button>
                            <select id="username" name="username" onChange={this.handleChange} value={formItems.username} className="btn btn-default" style={{ width: '250px', height: '30px' }}>
                                {retailer.map(agt =>
                                    <option value={agt.username}>{agt.fullname} ({agt.username})</option>
                                )}
                            </select>
                            
                            <button onClick={this.getJournaldata} className="btn btn-info">Display</button>
                        </div>}
                    </div>
                    <div className="col-xs-12"><hr className="hrsp" /></div>
                    <div className="col-xs-12 col-sm-12">
                        <BootstrapTable data={journaldata} striped hover condensed pagination search>
                            <TableHeaderColumn dataField='ticketSerial' width="180px" isKey dataSort dataFormat={this.ticketFormat}>Reference No.</TableHeaderColumn>
                            <TableHeaderColumn dataField='transSource' dataSort dataFormat={this.desFormat}>Description</TableHeaderColumn>
                            <TableHeaderColumn dataField='openBal' width="120px" dataFormat={this.amountFormat} dataAlign='right'  dataSort>Open Bal</TableHeaderColumn>
                            <TableHeaderColumn dataField='transSource' dataAlign="right" width="120px" dataFormat={this.drcrFormat} dataSort>Amount</TableHeaderColumn>
                            <TableHeaderColumn dataField='closeBal' width="120px" dataFormat={this.amountFormat} dataAlign='right' dataSort>Close Bal</TableHeaderColumn>
                            <TableHeaderColumn dataField='createdon' width="200px" dataFormat={this.dateFormat} dataSort>Created On</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    <RetailerForm  retailer={retailer}/>
                    <StakeTicketDetails ticketSerial={ticketSerial} />
                </div>
            )
        }
    }
}
