import axios from 'axios'
import Auth from './common/Auth'

export default class Config{ 

    DConnect(){
        return axios.create({
            baseURL: this.BaseURL() +'pmsresource/',
            timeout: 100000, 
            headers: {
              'Content-Type': 'application/json'
            }
        })
    }

    PostOptions(itms, area){ 
        return {
            method: 'post',
            url: this.BaseURL() + 'pmsresource/' + area,
            data: itms,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }
    }

    GetImage(itms, area){ 
        return {
            method: 'post',
            url: this.BaseURL() + 'pmsresource/' + area,
            data: itms,
            headers: {
                responseType: 'arraybuffer'
            }
        }
    }

    PostMulti(itms, area){
        return {
            method: 'post',
            url: this.BaseURL() + 'pmsresource/' + area,
            data: itms
        }
    }

    ImagePath(folder){
        let auth = new Auth()
        return(this.BaseURL() + folder );
    }

    BaseURL(){
        // return 'https://alphalottogh.com:8443/';
        return 'https://test.alphalottogh.com:8443/';
        //return 'http://myalphaonline.com:8081/test/';
        // return 'https://backend.vision2000lotto.com:8443/vision/';
        //return 'https://alphaoperator1.com:8443/';
        //return 'http://backend.vision2000lotto.com:8090/test/';
        //return 'http://192.168.165.179:8090/test/';
        // return 'http://85.31.236.120:8080/alpha_backend/';
        // return 'http://localhost:8085/alpha_project/';
    }
}