import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import Auth from '../common/Auth'
import Config from '../Config'
import { BrowserRouter, Link } from 'react-router-dom';
import RetailersList from './RetailersList';
import ProfileForm from '../administration/ProfileForm';
import UnitForm from './UnitForm';

const numformat = new Intl.NumberFormat("en-GB", {maximumFractionDigits: 2, minimumFractionDigits: 2});

export default class AgentList extends Component{
    constructor(){
        super()

        this.auth = new Auth()
        this.config = new Config()
        this.$http = this.config.DConnect()

        this.state = {
            operators: [],
            agents: [],
            allagents: [],
            agent: '',
            criteria: 0,
            filter: '',
            loader: false,
            usertypeItems:{
                username:'', usertype:'2', superior:'', bankname: '', area:'agent',
                curuser: this.auth.getUser(), token: this.auth.getToken()
            }
        }
    }

    componentDidMount(){
        let criteria = this.state.criteria
        if(this.props.criteria !== undefined){
            criteria = this.props.criteria
        }
        this.setState({ criteria, loader:true })
        this.getOperators()
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.state){
            let criteria = nextProps.criteria
            if(nextProps.criteria !== undefined){
                criteria = nextProps.criteria
            }
            this.setState({ criteria, loader:true })
            this.getOperators()
        }
    }

    getOperators = () =>{
        this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + "/userole")
        .then(res => {
            let role = this.auth.decryptData(res.data)
            let systemDefine = (role.id <= 2) ? '/usertypes/1' : '/usertypesoperator/' + this.auth.getUser() + '/' + 1
            if(role.id > 6){systemDefine = '/useroperator'}
            
            this.$http.get('admin/wapp/' + this.auth.getUser() + '/' + this.auth.getToken() + systemDefine)
            .then(res => {
                let resdata = this.auth.decryptData(res.data)
                
               let operators = []
                if(role.id > 6){
                    operators[0] = {username:resdata.username, usertype:1, 
                        fullname:resdata.firstname +' '+resdata.lastname, superior:'', status:1}
                }else{operators = resdata}
                let criteria = (operators[0]!==undefined && this.state.criteria==0) ? operators[0].username : this.state.criteria
                
                this.setState({ operators, criteria })

                this.getAgents(criteria)
            })//.catch((error)=>{alert('Operators: ' + error)})
        })//.catch((error)=>{alert('Role: ' + error)})
    }

    getAgents = (operator) =>{
        //alert(`admin/wapp/${this.auth.getUser()}/${this.auth.getToken()}/agentdetails/${operator}`)
        this.$http.get(`admin/wapp/${this.auth.getUser()}/${this.auth.getToken()}/agentdetails/${operator}`)
        .then(res => {
            let agents = this.auth.decryptData(res.data)
            
            // console.log(JSON.stringify(agents));
            this.setState({ agents, allagents:agents, filter:'', loader:false })
        }).catch((error)=>{alert(error)})
    }

    handleChange = (e) => {
        let criteria = this.state.criteria
        let filter = this.state.criteria
        let allagents = this.state.allagents
        let agents = this.state.agents
        let itm = e.target.name

        if(itm == 'criteria'){
            criteria = e.target.value
        }
        if(itm == 'filter'){
            filter = e.target.value
            agents = allagents.filter(ag => ag.username.startsWith(filter))
        }

        this.setState({ criteria, filter, agents })
    }

    showRetailers = (agent) => {
        ReactDOM.render(
            <RetailersList agent={agent} agents={this.state.agents} criteria={this.state.criteria} />,
            document.getElementById('agent_inner')
        )
    }

    newAgent = () =>{
        let usertypeItems = this.state.usertypeItems
        usertypeItems['superior'] = this.state.criteria

        ReactDOM.render(
            <ProfileForm username={''} uitems={usertypeItems} criteria={this.state.criteria} />,
            document.getElementById('agent_inner')
        )
    }

    transferUnit = (agent) =>{
        agent['usertype'] = 2;
        this.setState({ agent })
    }

    doDisplay = () => {
        this.getAgents(this.state.criteria)
    }

    loading = () => <div id="loader"></div>

    render(){
        if(this.state.loader){
            return this.loading()
        }else{
            return(
                <div className="row">
                    <div className="col-xs-12 clearfix">
                        <div className="btn-group btn-group-sm">
                            <button className="btn btn-default">Operator: </button>
                            <select name="criteria" className="btn btn-default" style={{width:'200px', height:'30px'}} 
                            value={this.state.criteria} onChange={this.handleChange}>
                                {this.state.operators.map(op => (
                                    <option value={op.username}>{op.fullname}</option>
                                ))} 
                            </select>
                            <button className="btn btn-info btnDisplay" onClick={this.doDisplay}>Display</button>
                        </div>
                        <div className="btn-group btn-group-sm pull-right">
                            <button className="btn btn-success" onClick={this.newAgent}>New Agent</button>
                        </div>
                        <hr className="hrsp" />
                        <div className="clearfix">
                            <input type="text" placeholder="Filter by Agent Code" className="form-control pull-right" style={{width:'200px', height:'30px'}} name="filter" 
                            value={this.state.filter} onChange={this.handleChange} />
                        </div>
                        <hr className="hrsp" />
                    </div>
                    {this.state.agents.map(agent => (
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            <div className="lockscreen animated flipInY table-bordered clearfix margin-bottom-10">
                                <img className="pull-left" src={this.config.ImagePath('custfiles') + '/' + agent.username + '.' + agent.filex} alt={agent.username} style={{maxHeight:'130px',maxWidth:'120px'}} />
                                <div className="pull-left padding-7 padding-bottom-0">
                                    <h3>{agent.fullname}</h3>
                                    <table id="tbAgentDetails" className="table table-condensed table-striped margin-bottom-0">
                                        <tbody>
                                            <tr>
                                                <td><a href={'tel:' + agent.mobile} className="text-primary"><i className="fa fa-phone"></i> {agent.mobile}</a></td>
                                                <td><i className="fa fa-key"></i> {agent.username}</td>
                                            </tr>
                                            <tr>
                                                <td><i className="fa fa-map-marker"></i> {agent.location} </td>
                                                <td>
                                                    <BrowserRouter>
                                                        <Link to="#" className="text-warning" onClick={()=>this.showRetailers(agent)}>
                                                            <i className="fa fa-users"></i> {agent.retailers}
                                                        </Link>
                                                    </BrowserRouter>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <i className="fa fa-money"></i> GH&cent;{numformat.format(agent.cashinhand)}
                                                </td>
                                                <td>
                                                    {agent.status > 0 && <a href="#" className="text-success" data-toggle="modal" data-target="#unitform" onClick={() => this.transferUnit(agent)}>
                                                        <i className="fa fa-credit-card"></i> {numformat.format(agent.unit)}
                                                    </a>}
                                                    {agent.status == 0 && <span><i className="fa fa-credit-card"></i> {numformat.format(agent.unit)}</span>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ))}
                    <UnitForm user={this.state.agent} /> 
                </div>
            )
        }
    }
}